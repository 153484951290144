 /* eslint-disable */
import React from 'react'
import { graphql, Link } from 'gatsby'


import Img from 'gatsby-image'

import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"



export default ({ data, pageContext: { node, locale, pagePath } }) => {

    let pageData = data.pageData.edges[0].node

    let globalData = data.globalData.edges[0].node

    let page_title_local = pageData.frontmatter[locale + "_title"] + " | " + globalData.frontmatter[locale + "_page_title_wooden_doors_prefix"] + " | " + globalData.frontmatter[locale + "_page_title_global_prefix"] + " " + globalData.frontmatter[locale + "_page_title_since_2001_prefix"]  
    let page_meta_description = pageData.frontmatter[locale + "_meta_desc"]
    let page_meta_keywords = pageData.frontmatter[locale + "_meta_keywords"]



    return (
        <>
        <SEO 
            pathname={pagePath}
            title={page_title_local}
            description={page_meta_description}
            keywords={page_meta_keywords}
        />

        <Header 
            locale={locale}
            pagePath={pagePath}
        />




             <div className="container">
                <div className="row">
                    {data.productPages.edges.sort((a, b) => {return(a.node.frontmatter.show_order - b.node.frontmatter.show_order)}).map(({ node }) => (
                        <>                        
                            <div className="col-12 col-sm-4 col-md-3 catalogueDoorCol">
                                <h4>{node.frontmatter.title}</h4>
                                <Link key={locale + '/catalogue/' + node.frontmatter.slug} to={`${locale === "he" ? '' : locale}/catalogue/${node.frontmatter.slug}`}>
                                    <Img fixed={node.frontmatter.img_a.childImageSharp.fixed} />
                                </Link>
                                {/* <pre>{JSON.stringify(node, null, 4)}</pre> */}
                            </div>
                        </>
                    ))}
                </div>
            </div>

            

            <Footer 
                locale={locale}
                pagePath={pagePath}
            />
        </>
    
    )
}




export const AllCatalogueImg = graphql`query AllCatalogueImg {
        pageData:
        allMarkdownRemark(filter: {frontmatter: {type: {eq: "catalogue_page"}}}) {
            edges {
                node {
                    frontmatter {
                        he_meta_desc
                        ru_meta_desc
                        en_meta_desc
                        pr_meta_desc
                        es_meta_desc
                        fr_meta_desc
                        he_meta_keywords
                        ru_meta_keywords
                        en_meta_keywords
                        pr_meta_keywords
                        es_meta_keywords
                        fr_meta_keywords
                        he_title
                        ru_title
                        en_title
                        pr_title
                        es_title
                        fr_title
                    }
                }
            }
        }
        productPages:
            allMarkdownRemark(filter: {frontmatter: {type: {eq: "productPage"}}}) {
                edges {
                    node {
                        frontmatter {
                            title
                            slug
                            show_order
                            img_a {
                                childImageSharp {
                                    fixed(width: 172) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
        globalData: 
            allMarkdownRemark(filter: {frontmatter: {type: {eq: "global_data"}}}) {
                edges {
                    node {
                        id
                        frontmatter {
                            he_page_title_global_prefix
                            he_page_title_since_2001_prefix
                            he_page_title_wooden_doors_prefix
                            he_door_series
                            fr_page_title_global_prefix
                            fr_page_title_since_2001_prefix
                            fr_page_title_wooden_doors_prefix
                            fr_door_series
                            en_page_title_global_prefix
                            en_page_title_since_2001_prefix
                            en_page_title_wooden_doors_prefix
                            en_door_series
                            pr_page_title_global_prefix
                            pr_page_title_since_2001_prefix
                            pr_page_title_wooden_doors_prefix
                            pr_door_series
                            es_page_title_global_prefix
                            es_page_title_since_2001_prefix
                            es_page_title_wooden_doors_prefix
                            es_door_series
                            ru_page_title_global_prefix
                            ru_page_title_since_2001_prefix
                            ru_page_title_wooden_doors_prefix
                            ru_door_series
                        }
                    }
                }
            }
    
  }`